<template>
  <v-row class="account">
    <v-col cols="12" class="pa-0">
      <div class="title">
        <div class="white--text text-uppercase text-sm-h5 text-h6 font-weight-bold">Account</div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <div v-else>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5">
          <div class="d-flex flex-wrap justify-space-between mb-sm-0 mb-3">
            <div class="text-uppercase text-h5 font-weight-bold">General</div>
            <v-hover v-slot="{ hover }">
              <div class="f18 status" :class="data.status == 'active' ? 'success' : data.status == 'new' ? 'warning' : 'error'">
                {{ data.status }}
                <v-fade-transition>
                  <div v-if="hover" class="accent pa-3 text-left rounded mt-3 status-info">
                    <div class="d-flex justify-space-between align-center">
                      <div class="f14">New</div>
                      <div class="warning rounded-circle" style="width: 8px; height: 8px"></div>
                    </div>
                    <div class="f14 opasity--text">
                      The account is created but waiting for review. All features will be available after the review finishes. Additional
                      information might be requested by the review team.
                    </div>
                    <v-divider class="white"></v-divider>
                    <div class="d-flex justify-space-between align-center">
                      <div class="f14">Active</div>
                      <div class="success rounded-circle" style="width: 8px; height: 8px"></div>
                    </div>
                    <div class="f14 opasity--text">The account is active and all features are available.</div>
                    <v-divider class="white"></v-divider>
                    <div class="d-flex justify-space-between align-center">
                      <div class="f14">Blocked</div>
                      <div class="error rounded-circle" style="width: 8px; height: 8px"></div>
                    </div>
                    <div class="f14 opasity--text">
                      The account is blocked due to a violation or other reasons. Please contact support to resolve the issue.
                    </div>
                  </div>
                </v-fade-transition>
              </div>
            </v-hover>
          </div>
          <div class="px-sm-10 py-sm-5">
            <v-row>
              <v-col cols="12" md="4">
                <div v-if="data.picture === null && !image">
                  <v-img @click="changeAvatar()" width="256" src="@/assets/img/ava.svg" class="link mx-md-0 mx-auto"></v-img>
                </div>
                <div v-else class="mx-auto">
                  <v-img @click="changeAvatar()" width="256" height="216" cover :src="image || data.picture" class="link"></v-img>
                </div>
                <div class="mt-4 d-flex flex-md-nowrap justify-md-space-between justify-center">
                  <v-btn
                    :disabled="!data.picture || !data.can_edit"
                    class="mr-lg-0 mr-8 rounded"
                    width="48"
                    height="48"
                    icon
                    tile
                    elevation="0"
                    @click="deletePhote"
                  >
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                    elevation="0"
                    class="mr-md-8 mr-0"
                    color="secondary"
                    max-width="185"
                    width="100%"
                    height="48"
                    @click="updatePhote"
                    :disabled="!file || !data.can_edit"
                  >
                    Update
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="data.name"
                  :error-messages="nameErrors"
                  label="Account Name"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                  maxlength="50"
                  :disabled="!data.can_edit"
                ></v-text-field>
                <v-text-field
                  v-model="data.main_email"
                  :error-messages="emailErrors"
                  label="Main Email"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                  :disabled="!data.can_edit"
                ></v-text-field>
                <div class="mt-n6" v-if="!emailErrors.length">
                  <div v-if="data.is_email_confirmed" class="success--text">
                    Confirmed {{ new Date(data.email_confirmed_at).toLocaleString() }}
                  </div>
                  <div v-else>
                    <div class="error--text">Your email is not confirmed yet. Please check your email Inbox.</div>
                    <div class="input--text link mt-3" @click="sendEmail">Re-sent confirmation link</div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-10"></v-divider>
            <div class="text-sm-right text-center">
              <v-btn :disabled="!data.can_edit" elevation="0" width="180" height="48" color="primary" @click="update">Save</v-btn>
            </div>
          </div>
        </v-card>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5 mt-5">
          <div class="text-uppercase text-h5 font-weight-bold mb-sm-0 mb-3">Service/billing address</div>
          <div class="px-sm-10 py-sm-5">
            <v-row>
              <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="address.country"
                  :items="countryList"
                  label="Country"
                  :error-messages="countryErrors"
                  filled
                  outlined
                  dense
                  required
                  item-text="name"
                  item-value="id"
                  color="input"
                  @change="getCity"
                ></v-autocomplete>
                <v-autocomplete
                  v-model="address.city"
                  :items="cityList"
                  label="City"
                  :error-messages="cityErrors"
                  filled
                  outlined
                  dense
                  required
                  item-text="name"
                  item-value="id"
                  color="input"
                ></v-autocomplete>
                <v-text-field
                  v-model="address.state"
                  :error-messages="stateErrors"
                  label="State"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                  maxlength="50"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="address.address"
                  :error-messages="addressErrors"
                  label="Street"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                  maxlength="50"
                ></v-text-field>
                <v-text-field
                  v-model="address.postal_code"
                  :error-messages="postalCodeErrors"
                  label="Zip Code"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                  maxlength="50"
                ></v-text-field>
                <v-textarea
                  v-model="address.notes"
                  label="Additional notes"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                  rows="1"
                  auto-grow
                ></v-textarea>
              </v-col>
            </v-row>
            <v-divider class="my-10"></v-divider>
            <div class="text-sm-right text-center">
              <v-btn :disabled="!address.can_edit" elevation="0" width="180" height="48" color="primary" @click="updateAddress">Save</v-btn>
            </div>
          </div>
        </v-card>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 mt-5">
          <div class="text-uppercase text-h5 font-weight-bold pt-sm-10 px-sm-10 px-4 pt-5 mb-2">members</div>
          <v-divider></v-divider>
          <v-data-table class="p-table" hide-default-footer fixed-header :headers="headers" :items="members" item-key="user">
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id" height="60" class="link" @click="openEdit(item)">
                  <td>
                    <div class="d-flex align-center">
                      <div class="mr-3 d-sm-block d-none">
                        <v-avatar v-if="item.user.photo" size="48">
                          <v-img :src="item.user.photo"></v-img>
                        </v-avatar>
                        <v-avatar v-else color="gray" size="48">
                          <v-icon>mdi-account mdi-36px</v-icon>
                        </v-avatar>
                      </div>
                      <div>{{ item.user.first_name }} {{ item.user.last_name }}</div>
                    </div>
                  </td>
                  <td class="d-sm-table-cell d-none">
                    <div>{{ item.user.email }}</div>
                  </td>
                  <td class="text-capitalize">
                    {{ item.role }}
                  </td>
                  <td class="text-capitalize">
                    {{ item.status }}
                  </td>
                  <td class="text-right">
                    <v-btn :disabled="!item.can_remove" @click.stop="openDelete(item)" class="rounded" height="48" icon tile elevation="0">
                      <v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <div class="px-7">
            <v-btn class="my-2" height="48" text color="input" @click="inviteUser = !inviteUser">
              <v-icon class="input rounded-circle btn-icon" color="white" left>mdi-plus mdi-24px</v-icon>
              Invite member
            </v-btn>
          </div>
        </v-card>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5 mt-5">
          <div class="text-uppercase text-h5 font-weight-bold">billing conditions</div>
          <div class="px-sm-10 pb-sm-5">
            <v-row>
              <v-col cols="12" sm="6">
                <div class="text-h6 font-weight-bold mt-5">Billing currency</div>
                <div class="f18 opasity--text text-uppercase">{{ billing.currency.name }} ({{ billing.currency.title }})</div>
                <div v-if="billing.payment_mode.name == 'postpay'">
                  <div class="text-h6 font-weight-bold mt-5">Billing delay, days</div>
                  <div class="f18 opasity--text">{{ billing.billing_delay }} days</div>
                  <div class="text-h6 font-weight-bold mt-5">Billing period</div>
                  <div class="f18 opasity--text">{{ billing.billing_period.title }}</div>
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <div class="text-h6 font-weight-bold mt-sm-5">Billing mode</div>
                <div class="f18 opasity--text">{{ billing.payment_mode.title }}</div>
                <div v-if="billing.payment_mode.name == 'postpay'">
                  <div class="text-h6 font-weight-bold mt-5">Overdraft limit</div>
                  <div class="f18 opasity--text">{{ billing.overdraft }}</div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
      <v-dialog v-model="inviteUser" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
          <div class="text-h5 font-weight-bold mb-6">Invite member</div>
          <v-text-field
            v-model="invite.email"
            label="Email"
            :error-messages="emailInErrors"
            filled
            outlined
            dense
            required
            color="input"
          ></v-text-field>
          <v-select
            :items="roleList"
            v-model="invite.role"
            :error-messages="roleErrors"
            label="Role"
            item-text="title"
            item-value="name"
            filled
            outlined
            dense
            required
            color="input"
          >
          </v-select>
          <div class="mt-5 d-flex flex-column align-center">
            <v-btn width="184" height="48" color="primary" @click="sendInvite">Sent</v-btn>
            <v-btn width="184" height="48" text class="mt-3" @click="inviteUser = !inviteUser">Close</v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editUser" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
          <div class="d-flex flex-wrap align-center mb-5">
            <div>
              <v-avatar v-if="editedItem.user.photo" size="94">
                <v-img :src="editedItem.user.photo"></v-img>
              </v-avatar>
              <v-avatar v-else color="gray" size="94">
                <v-icon>mdi-account mdi-112px</v-icon>
              </v-avatar>
            </div>
            <div class="ml-5">
              <div class="text-h5 font-weight-bold">{{ editedItem.user.first_name }} {{ editedItem.user.last_name }}</div>
            </div>
          </div>
          <div class="f18 font-weight-medium">Email</div>
          <div class="opasity--text f18 d-flex flex-wrap justify-space-between align-end">
            <span>{{ editedItem.user.email }}</span>
            <span v-if="editedItem.can_resend_invite" @click="invitationAccountMembers(editedItem.id)" class="input--text text-body-1 link">
              Re-sent invitation
            </span>
          </div>
          <div class="f18 font-weight-medium my-5">
            Status
            <span class="d-inline-block status-small f14 px-3 ml-4" :style="getColorClass(editedItem.status)">{{ editedItem.status }}</span>
          </div>
          <v-select
            :items="roleList"
            v-model="editedItem.role"
            label="Role"
            item-text="title"
            item-value="name"
            hide-details
            filled
            outlined
            dense
            :disabled="!editedItem.can_edit"
            color="input"
          >
          </v-select>
          <div class="mt-10 d-flex flex-column align-center">
            <v-btn width="184" height="48" color="primary" :disabled="!editedItem.can_edit" @click="updateMembers(editedItem)">Save</v-btn>
            <v-btn width="184" height="48" text class="mt-3" @click="closeDialog">Close</v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteUser" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4 text-center">
          <div class="text-h5 font-weight-bold">Delete user</div>
          <div class="mt-3 opasity--text">
            Do you really want to delete {{ editedItem.user.first_name }} {{ editedItem.user.last_name }}?
          </div>
          <div class="mt-7">
            <v-btn width="120" height="48" class="mr-4 white--text" color="gray" @click="closeDialog">no</v-btn>
            <v-btn width="120" height="48" color="secondary" @click="deleteAccountMembers(editedItem.id)">yes</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    data: {
      name: { required },
      main_email: { required, email },
    },
    address: {
      address: { required },
      city: { required },
      postal_code: { required },
      country: { required },
    },
    invite: {
      role: { required },
      email: { required, email },
    },
  },
  data() {
    return {
      load: true,
      file: '',
      image: '',
      headers: [
        { text: 'User', value: 'firct_name', sortable: false },
        { text: 'Email', class: 'd-sm-table-cell d-none', value: 'email', sortable: false },
        { text: 'Role', value: 'role', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Delete', align: 'end', value: 'delete', sortable: false },
      ],
      inviteUser: false,
      editUser: false,
      deleteUser: false,
      invite: {},
      editedItem: { user: {} },
      error: null,
    };
  },
  mounted() {
    this.getAddress();
    this.$store.dispatch('getCoutries');
    this.$store.dispatch('getAccountMembers');
    this.$store.dispatch('getAccountBilling');
    this.$store.dispatch('getRoles').finally(() => {
      this.load = false;
    });
  },
  methods: {
    getColorClass(block) {
      if (block == 'active') {
        return 'color: #65C006; background: #F0FEE1;';
      } else if (block == 'invited') {
        return 'color: #944D00; background: #FFEBD6;';
      } else if (block == 'rejected') {
        return 'color: #FF4F4F; background: #FFE0E0;';
      }
    },
    notifi(btn) {
      if (btn == 'update') {
        this.$notify({ message: 'Your account has been updated', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'confirm') {
        this.$notify({ message: 'Email confirmation was sent successfully', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'invite') {
        this.$notify({ message: 'Email invite was sent successfully', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'updatePhoto') {
        this.$notify({ message: 'Your profile picture was successfully updated', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'updateAddress') {
        this.$notify({ message: 'Your address has been updated', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (this.error == 'credential__invalid') {
        this.$notify({
          message: 'Provided email or password is not correct. Please check and try again',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error == 'confirmation__too_often') {
        this.$notify({
          message: 'Sorry, but email confirmation requests limit exceeded. Try again later',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error == 'email__already_confirmed') {
        this.$notify({ message: 'Email is already confirmed', type: 'warning', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (this.error == 'email__update_too_often ') {
        this.$notify({
          message: 'Sorry, but email updates limit exceeded. Try again later',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top'
        });
      }
    },
    async getAddress() {
      await this.$store.dispatch('getAccountAddress');
    },
    async getCity() {
      await this.$store.dispatch('getCity', this.address.country);
    },
    async update() {
      this.error = null;
      this.$v.data.$touch();
      if (!this.$v.data.$invalid) {
        const data = {
          name: this.data.name,
          main_email: this.data.main_email,
        };
        await this.$store
          .dispatch('updateAccount', data)
          .then(() => {
            this.notifi('update');
          })
          .catch((e) => {
            this.error = e.response.data.error[0];
            this.notifi();
          });
      }
    },
    changeAvatar() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.image = e.target.result;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    async updatePhote() {
      await this.$store.dispatch('setPhotoAcc', this.file).then(() => {
        this.file = '';
        this.notifi('updatePhoto');
      });
    },
    async deletePhote() {
      await this.$store.dispatch('deletePhotoAcc').then(() => {
        this.image = '';
      });
    },
    async sendEmail() {
      await this.$store
        .dispatch('sendEmailAcc')
        .then(() => {
          this.notifi('confirm');
        })
        .catch((e) => {
          this.error = e.response.data.error[0];
          this.notifi();
        });
    },
    async updateAddress() {
      this.$v.address.$touch();
      if (!this.$v.address.$invalid) {
        this.error = null;
        const data = {
          address: this.address.address,
          city: this.address.city,
          state: this.address.state,
          country: this.address.country,
          postal_code: this.address.postal_code,
          notes: this.address.notes,
        };
        await this.$store
          .dispatch('updateAccountAddress', data)
          .then(() => {
            this.notifi('updateAddress');
          })
          .catch((e) => {
            this.error = e.response.data.error[0];
            this.notifi();
          });
      }
    },
    async updateMembers(item) {
      const data = new Object();
      data.role = item.role;
      await this.$store
        .dispatch('updateAccountMembers', {
          id: item.id,
          data: data,
        })
        .then(() => {
          this.closeDialog();
        });
    },
    async invitationAccountMembers(id) {
      await this.$store.dispatch('invitationAccountMembers', id).then(() => {
        this.closeDialog();
      });
    },
    async deleteAccountMembers(id) {
      await this.$store.dispatch('deleteAccountMembers', id).then(() => {
        this.closeDialog();
      });
    },
    async sendInvite() {
      this.error = null;
      this.$v.invite.$touch();
      if (!this.$v.invite.$invalid) {
        const data = new Object();
        data.email = this.invite.email;
        data.role = this.invite.role;
        await this.$store
          .dispatch('sentInviteMembers', data)
          .then(() => {
            this.inviteUser = false;
            this.notifi('invite');
          })
          .catch((e) => {
            this.error = e.response.data.error[0];
            this.notifi();
          });
      }
    },
    openEdit(item) {
      this.editedItem = Object.assign({}, item);
      this.editUser = true;
    },
    openDelete(item) {
      this.editedItem = Object.assign({}, item);
      this.deleteUser = true;
    },
    closeDialog() {
      this.editUser = false;
      this.deleteUser = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, { user: {} });
      });
    },
  },
  watch: {
    inviteUser() {
      this.$v.invite.$reset();
      this.invite = {};
    },
    editUser(val) {
      val || this.closeDialog();
    },
    deleteUser(val) {
      val || this.closeDialog();
    },
    countryList() {
      setTimeout(() => {
        this.getCity();
      }, 1000);
    },
  },
  computed: {
    data() {
      return this.$store.getters.account;
    },
    address() {
      return this.$store.getters.accountAddress;
    },
    countryList() {
      return this.$store.getters.countries;
    },
    cityList() {
      return this.$store.getters.city;
    },
    members() {
      return this.$store.getters.members;
    },
    roleList() {
      return this.$store.getters.roleList;
    },
    billing() {
      return this.$store.getters.billing;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.data.name.$dirty) {
        return errors;
      }
      !this.$v.data.name.required && errors.push('Please enter your name');
      this.error == 'name__invalid' && errors.push('Provided name is not valid');
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.data.main_email.$dirty) {
        return errors;
      }
      !this.$v.data.main_email.email && errors.push('Provided email address is not valid');
      !this.$v.data.main_email.required && errors.push('Please enter your email address');
      this.error == 'main_email__invalid' && errors.push('Provided email address is not valid');
      this.error == 'main_email__exists' && errors.push('Provided email address is already registered. Please use another one');
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.address.address.$dirty) {
        return errors;
      }
      !this.$v.address.address.required && errors.push('Please enter your address');
      this.error == 'address__invalid' && errors.push('Provided address is not valid');
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.address.city.$dirty) {
        return errors;
      }
      !this.$v.address.city.required && errors.push('Please enter your city');
      this.error == 'city__invalid' && errors.push('Provided city is not valid');
      return errors;
    },
    countryErrors() {
      const errors = [];
      if (!this.$v.address.country.$dirty) {
        return errors;
      }
      !this.$v.address.country.required && errors.push('Please enter your country');
      this.error == 'country__invalid' && errors.push('Provided country is not valid');
      return errors;
    },
    stateErrors() {
      const errors = [];
      this.error == 'state__invalid' && errors.push('Provided state is not valid');
      return errors;
    },
    postalCodeErrors() {
      const errors = [];
      if (!this.$v.address.postal_code.$dirty) {
        return errors;
      }
      !this.$v.address.postal_code.required && errors.push('Please enter your zip code');
      this.error == 'postal_code__invalid' && errors.push('Provided zip code is not valid');
      return errors;
    },
    emailInErrors() {
      const errors = [];
      if (!this.$v.invite.email.$dirty) {
        return errors;
      }
      !this.$v.invite.email.email && errors.push('Provided email address is not valid');
      !this.$v.invite.email.required && errors.push('Please enter email address');
      this.error == 'email__invalid' && errors.push('Provided email address is not valid');
      this.error == 'email__exists' && errors.push('Provided email address is already registered. Please use another one');
      return errors;
    },
    roleErrors() {
      const errors = [];
      if (!this.$v.invite.role.$dirty) {
        return errors;
      }
      !this.$v.invite.role.required && errors.push('Please enter role');
      this.error == 'role__invalid' && errors.push('Provided role is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setAccountAddress', {});
    this.$store.dispatch('setCoutries', []);
    this.$store.dispatch('setCity', []);
    this.$store.dispatch('setAccountMembers', []);
    this.$store.dispatch('setAccountBilling', {
      billing_period: {},
      currency: {},
      payment_mode: {},
    });
  },
};
</script>

<style lang="scss">
.account {
  .status {
    text-align: center;
    text-transform: capitalize;
    line-height: 42px;
    width: 174px;
    height: 42px;
    border-radius: 24px;
    color: white;
  }
  .status-info {
    position: absolute;
    line-height: 24px;
    left: 50%;
    z-index: 9999;
    width: 364px;
  }
  .btn-icon {
    width: 32px !important;
    height: 32px !important;
  }
  @media screen and (max-width: 649px) {
    .status-info {
      width: 304px;
      left: calc(50% - 152px);
    }
  }
}
.v-dialog {
  .status-small {
    text-align: center;
    text-transform: capitalize;
    line-height: 26px;
    height: 26px;
    border-radius: 24px;
  }
  .v-avatar .v-icon {
    font-size: 84px;
  }
}
</style>
